<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3
          class="card-label font-weight-bolder text-dark"
          v-if="record.id != -1"
        >
          Edit Assets & Systems <small>(v:{{userRecord.updated_at.substring(0, 10)}})</small>
        </h3>
        <h3
          class="card-label font-weight-bolder text-dark"
          v-if="record.id == -1"
        >
          Create Assets & Systems
        </h3>
      </div>
      <div class="card-toolbar">
        <button
          type="reset"
          class="btn btn-success mr-2"
          @click="save()"
          ref="kt_save_changes"
        >
          Save Changes
        </button>
        <button type="reset" class="btn btn-secondary" @click="cancel()">
          Cancel
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Name</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              ref="name"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model.trim="$v.userRecord.name.$model"
            />
            <div class="error" v-if="!$v.userRecord.name.required">
              <i>Name is required</i>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >System / Asset Type</label
          >
          <div class="col-lg-9 col-xl-6">
            <b-form-select ref="category" v-model="userRecord.category" :options="options"></b-form-select>
            <div class="error" v-if="!$v.userRecord.category.required">
              <i>System/Asset Type is required</i>
            </div>
          </div>
        </div> 
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >System Owner</label
          >
          <div class="col-lg-9 col-xl-6">
            <b-form-select
              ref="owner_user_id" 
              v-model="userRecord.owner_user_id"
              :options="userOptions"></b-form-select>
            <div class="error" v-if="!$v.userRecord.owner_user_id.required">
              <i>System Owner is required</i>
            </div>
          </div>
        </div>     



        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            ></label
          >
          <div class="col-lg-9 col-xl-6">
            <p>Impacts : For each of the following impacts select the impact on your organisations operations, cash-flow, legal position, contractual obligations & brand.</p>
          </div>
        </div> 


        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Confidentiality</label
          >
          <div class="col-lg-9 col-xl-6">
            <b-form-select
              ref="confidentiality" 
              v-model="userRecord.confidentiality"
              :options="impactOptions"></b-form-select>
            <div class="error" v-if="!$v.userRecord.confidentiality.required">
              <i>Confidentiality Impact  is required</i>
            </div>
          </div>
        </div>             

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Integrity</label
          >
          <div class="col-lg-9 col-xl-6">
            <b-form-select
              ref="integrity" 
              v-model="userRecord.integrity"
              :options="impactOptions"></b-form-select>
            <div class="error" v-if="!$v.userRecord.integrity.required">
              <i>Integrity Impact  is required</i>
            </div>
          </div>
        </div>



        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Availability</label
          >
          <div class="col-lg-9 col-xl-6">
            <b-form-select
              ref="availability" 
              v-model="userRecord.availability"
              :options="impactOptions"></b-form-select>
            <div class="error" v-if="!$v.userRecord.availability.required">
              <i>Availability Impact  is required</i>
            </div>
          </div>
        </div>   



        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Notes</label
          >
          <div class="col-lg-9 col-xl-6">
            <vue-editor ref="notes" v-model="userRecord.notes" />
          </div>
        </div>
      </div>
    </form>

          <div v-if="getThreatData.length > 0" class="card-header py-3" >
            <h3>Known threats</h3>
            <b-table
              striped
              hover
              sort-icon-left          
              :items="getThreatData"
              :fields="threatFields"
              @row-clicked="rowClickHandlerThreat"
            >
              <template #cell(risk)="data">
                <div :class="riskclass(data.item.risk)">{{data.item.risk}}</div>
              </template>
              <template #cell(frameworks)="data">
                {{showframeworks(data.item.frameworks)}}
              </template> 
            </b-table>               
          </div>



    <audit-notes :id="userRecord.id" action="System"/>

    <!--end::Form-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  UPDATE_SYSTEM_DETAILS,
  CREATE_SYSTEM_DETAILS,
} from "@/core/services/store/systems.module";

import { GET_THREATS } from "@/core/services/store/threats.module";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import AuditNotes from "@/view/content/sections/AuditNotes";

export default {
  components: {AuditNotes},
  mixins: [validationMixin],
  name: "PersonalInformation",
  props: {
    record: Object,
  },
  data() {
    return {
      userRecord: this.record,
        options: [
          { value: '', text: 'Please select an option' },
          { value: 'Cloud Services', text: 'Cloud Services' },
          { value: 'Data Asset', text: 'Data Asset' },
          { value: 'Hardware', text: 'Hardware' },
          { value: 'Personnel', text: 'Personnel' },
          { value: 'Physical Building/Office', text: 'Physical Building/Office' },
          { value: 'Software', text: 'Software' },
          { value: 'Third Party Vendors', text: 'Third Party Vendors' },
        ],
      threatFields: [
        { key: "reference" , sortable: true},
        { key: "name", label: "Name", sortable: true },
        { key: "risk" , sortable: true },
        { key: "frameworks", label: "Controls" , sortable: true },
      ],
      impactOptions: [
          { value: '', text: 'Please select an option' },
          { value: 'No Impact(1)', text: 'No Impact(1)' },
          { value: 'Minimal Impact(2)', text: 'Minimal Impact(2)' },
          { value: 'Notable Impact(3)', text: 'Notable Impact(3)' },
          { value: 'Significant Impact(4)', text: 'Significant Impact(4)' },
          { value: 'Substantial Impact(5)', text: 'Substantial Impact(5)' },
      ]



    };
  },
  validations: {
    userRecord: {
      name: {
        required,
      },
      category: {
        required,
      },
      owner_user_id: {
        required,
      },
      confidentiality: {
        required,
      },
      integrity: {
        required,
      },
      availability: {
        required,
      }, 
    },
  },
  mounted() {
    console.log(this.currentUser);
    this.$store.dispatch(GET_THREATS);
  },
  methods: {
    save() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        var name = this.$refs.name.value;
        var notes = this.userRecord.notes;
        var category = this.$refs.category.value;
        var confidentiality = this.$refs.confidentiality.value;        
        var integrity = this.$refs.integrity.value;        
        var availability = this.$refs.availability.value;        
        var owner_user_id = this.$refs.owner_user_id.value;
        
        var id = this.userRecord.id;

        // set spinner to submit button
        const submitButton = this.$refs["kt_save_changes"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        if (id == -1) {
          // send update request
          this.$store
            .dispatch(CREATE_SYSTEM_DETAILS, {
              id,
              name,
              notes,
              category,
              availability,
              integrity,
              confidentiality,
              owner_user_id
            })
            .then(() => {
              this.$emit("finished");
            });
        } else {
          // send update request
          this.$store
            .dispatch(UPDATE_SYSTEM_DETAILS, {
              id,
              name,
              notes,
              category,
              availability,
              integrity,
              confidentiality,
              owner_user_id
            })
            .then(() => {
              this.$emit("finished");
            });
        }

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }
    },
    cancel() {
      this.$emit("canceled");
    },
    riskclass(value) {
        if (value == 0 ) { return 'transparentrisk' }      
        if (value <= 3 ) { return 'greenrisk' }
        if (value <= 7 ) { return 'yellowrisk' }
        if (value <= 10 ) { return 'orangerisk' }
        if (value <= 25 ) { return 'redrisk' }
        return '';
    },   
    showsystems(values) {
      var retVal = '';
      if (values) {
        values.forEach(value => {
          if (retVal == '' ) { retVal = value.name } else { retVal = retVal + ', ' + value.name}
          
        });
      }
      if (retVal == '') { retVal = '-'}
      return retVal;
    },
    showframeworks(values) {
      var retVal = '';
      if (values) {
        values.forEach(value => {
          if (retVal == '' ) { retVal = value.name } else { retVal = retVal + ', ' + value.name}
          
        });
      }
      if (retVal == '') { retVal = '-'}
      return retVal;
    },    
    rowClickHandlerThreat() {      
      this.$router.push({ name: "threatsbyID" , params: { system: this.userRecord.id} });
    },      
  },
  computed: {
    ...mapGetters(["currentFramework","currentUser","currentThreatsData"]),
    getThreatData() {
      if (this.currentThreatsData && this.userRecord.id != -1) {
        console.log('---',this.userRecord)
        return this.currentThreatsData.filter((item) => {
          return (JSON.stringify(item.systems).includes(this.userRecord.id))
        })
      } else {
        return [];
      }
    },
    userOptions() {
      var results = [];
      this.currentFramework.users.forEach(user => {
        results.push({
          value: user.id,
          text: user.name
        });
      });
      return results;
    },    
  },
  watch: {
    record: function(newVal, oldVal) {
      // watch it
      console.log("Prop changed: ", newVal, " | was: ", oldVal);
    },
  },
};
</script>
<style>
.error i {
  color: #cc0000;
}
</style>
