<template>
  <div>
    <b-card>
      <div class="card-toolbar">
        <button
          type="reset"
          class="btn btn-success mr-2"
          @click="create()"
          ref="kt_save_changes"
          v-if="!editSystem"
        >
          Add New Asset or System
        </button>
      </div>
      <div v-if="!editSystem">
        <b-table
          striped
          hover
          sort-icon-left          
          :items="currentSystemsData"
          :fields="systemFields"
          sort-by="name"
          :sort-desc="false"
          @row-clicked="rowClickHandler"
         :tbody-tr-class="rowClass"         
        >
          <template #cell(actions)="data">
            <v-icon small v-on:click="deleteRecord(data.item.id,data.item.active)" v-if="data.item.active"
              >mdi-delete</v-icon
            >
            <v-icon small v-on:click="deleteRecord(data.item.id,data.item.active)" v-if="!data.item.active"
              >mdi-restart</v-icon
            >
          </template>
          <template #cell(notes)="data">
            <p class="trimNotes"
              v-html="data.item.notes">
            </p>          
          </template>          
          <template #cell(name)="data">
              {{data.item.name}}<small> (v:{{data.item.updated_at.substring(0, 10)}})</small>
          </template>          
          <template #cell(owner_user_id)="data">
            {{userName(data.item.owner_user_id)}}
          </template>          
        </b-table>
      </div>

      <div v-if="editSystem">
        <KTSystemInformation
          :record="editSystemRecord"
          @canceled="editSystem = false"
          @finished="finished()"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import {
  GET_SYSTEMS,
  DELETE_SYSTEM_DETAILS,
} from "@/core/services/store/systems.module";
import {
  GET_FRAMEWORK,
} from "@/core/services/store/framework.module";

import KTSystemInformation from "@/view/pages/management/systems/SystemsInformation";

export default {
  name: "managesystems",
  components: {
    KTSystemInformation,
  },
  data() {
    return {
      editSystem: false,
      editSystemRecord: [],

      systemFields: [
        { key: "name", label: "Name", sortable: true },
        { key: "category", label: "System / Asset Type", sortable: true },        
        { key: "notes" },
        { key: "owner_user_id", label: "Owner" },
        { key: "confidentiality", label: "Confidentiality" },
        { key: "integrity", label: "Integrity" },
        { key: "availability", label: "Availability" },
        { key: "actions", label: "" },
      ],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Manage Assets & Systems" }]);
    this.getData();
  },
  methods: {
    getData() {
      this.$store.dispatch(GET_SYSTEMS);
      this.$store.dispatch(GET_FRAMEWORK);
    },
    finished() {
      this.editSystem = false;
      this.editSystemRecord = [];
      this.$router.push('/management/system');      
        setTimeout(() => {
          this.getData();
        }, 500);      
    },
    rowClickHandler(record) {
      this.$router.push('/management/system/' + record.id);      
    },

    create() {
      this.editSystem = true;
      this.editSystemRecord = {
        id: -1,
        name: "",
        notes: "",
        owner_user_id: "",
        confidentiality: "",
        integrity: "",
        availability: "",
      }
    },
    rowClass(item, type) {
      console.log(type,item)
        if (item.active != true) { 
          return 'text-deleted'
        } else {
          return ''
        } 
    },        
    deleteRecord(id,active) {
      var text = "Do you really want to delete?";
      if (!active ) {text = "Do you really want to restore?"; }
      if (confirm(text)) {
        this.editSystem = false;
        this.$store.dispatch(DELETE_SYSTEM_DETAILS, {
          id,
        });
        setTimeout(() => {
          this.getData();
        }, 500);   
      }
    },
    userName(owner_user_id) {
      var result = 'n/a';
      this.currentFramework.users.forEach(user => {
        if (user.id == owner_user_id ) {
          result = user.name;
        }
      });
      return result;
    },       
  },
  computed: {
    ...mapGetters(["currentSystemsData","currentFramework"]),
    getName() {
      var result = "";
      if (this.currentFramework.framework) {
        result = this.currentFramework.framework.name;
      }
      return result;
    },
    getFrameworkHeaders() {
      var results = [];
      if (this.currentFramework.framework_items) {
        this.currentFramework.framework_items.forEach((framework_item) => {
          var targetListResult = results.filter(function(o) {
            return o.function_code == framework_item.function_code;
          });
          if (targetListResult.length === 0) {
            results.push({
              function_code: framework_item.function_code,
              function: framework_item.function,
            });
          }
        });
      }
      return results;
    },
     
  },
  watch: {
    currentSystemsData: function() {
        if (this.$route.params.id) {
          
          this.currentSystemsData.forEach((record) => {
            if (this.$route.params.id == record.id) {
              this.editSystemRecord = record;              
              this.editSystem = true;
            }
          })
          
          
        }         
    },
    '$route' () {
      if (!this.$route.params.id) {this.editSystem = false;} else {          
          this.currentSystemsData.forEach((record) => {
            if (this.$route.params.id == record.id) {
              this.editSystemRecord = record;              
              this.editSystem = true;
            }
          })
      }
    }
  },     
};
</script>
<style>
.table-hover tr td {
    cursor: pointer;
}
.trimNotes {
  height:100px;
  overflow: hidden;
}
</style>